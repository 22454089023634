// Header.js
// Hauptkomponente für den Seitenkopf mit Navigation und Dark Mode Toggle

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDarkMode } from '../App'; 
import '../assets/css/global.css';  
import '../assets/css/header.css';  

const Header = () => {
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  
  // State-Variablen für Scroll-Verhalten, Dropdown und Hover-Effekte
  const [scrollUp, setScrollUp] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  let lastScrollPosition = 0;

  // Effekt zum Überwachen des Scroll-Verhaltens
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setScrollUp(currentScrollPosition <= lastScrollPosition);
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Hilfsfunktionen zur Bestimmung des aktiven Menüpunkts
  const isPortfolioActive = () => {
    return ['/portfolio', '/architektur', '/interieurfotografie', '/produktfotografie'].includes(location.pathname);
  };

  const isPortfolioSubpage = () => {
    return ['/architektur', '/interieurfotografie', '/produktfotografie'].includes(location.pathname);
  };

  // Event-Handler für Hover-Effekte
  const handlePortfolioHover = () => {
    if (isPortfolioSubpage()) {
      setShowDropdown(true);
    }
  };

  const handlePortfolioLeave = () => {
    setShowDropdown(false);
  };

  const handleMenuItemHover = (item) => {
    setHoveredItem(item);
  };

  const handleMenuLeave = () => {
    setHoveredItem(null);
  };

  // Funktion zur Bestimmung der CSS-Klasse für Menüpunkte
  const getMenuItemClass = (path) => {
    if (hoveredItem === path) {
      return 'hovered';
    }
    if (hoveredItem === null && (
      (path === '/portfolio' && isPortfolioActive()) ||
      location.pathname === path
    )) {
      return 'current';
    }
    return '';
  };

  // Render des Headers mit Navigation und Dark Mode Toggle
  return (
    <header className={scrollUp ? "" : "header_up"}>
      <a className="logo" href="/">
        <img src="/assets/images/logo.png" alt="logo" />
      </a>
      <nav onMouseLeave={handleMenuLeave}>
        <div className="menu">
          <ul>
            {/* Portfolio-Menüpunkt mit Dropdown */}
            <li 
              onMouseEnter={() => {
                handleMenuItemHover('/portfolio');
                handlePortfolioHover();
              }}
              onMouseLeave={handlePortfolioLeave}
              className="portfolio-menu-item"
            >
              <Link to="/portfolio" className={getMenuItemClass('/portfolio')}>
                Portfolio
              </Link>
              {showDropdown && (
                <ul className="dropdown">
                  <li><Link to="/architektur">Architektur</Link></li>
                  <li><Link to="/interieurfotografie">Interieur</Link></li>
                  <li><Link to="/produktfotografie">Produkt</Link></li>
                </ul>
              )}
            </li>
            {/* Weitere Menüpunkte */}
            <li onMouseEnter={() => handleMenuItemHover('/leistungen')}>
              <Link to="/leistungen" className={getMenuItemClass('/leistungen')}>
                Leistungen
              </Link>
            </li>
            <li onMouseEnter={() => handleMenuItemHover('/referenzen')}>
              <Link to="/referenzen" className={getMenuItemClass('/referenzen')}>
                Referenzen
              </Link>
            </li>
            <li onMouseEnter={() => handleMenuItemHover('/ueber')}>
              <Link to="/ueber" className={getMenuItemClass('/ueber')}>
                Über
              </Link>
            </li>
            <li onMouseEnter={() => handleMenuItemHover('/kontakt')}>
              <Link to="/kontakt" className={getMenuItemClass('/kontakt')}>
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
        {/* Dark Mode Toggle */}
        <div className="dark-mode-toggle">
          <input
            type="checkbox"
            id="darkModeToggle"
            className="toggle-checkbox"
            checked={isDarkMode}
            onChange={toggleDarkMode}
          />
          <label htmlFor="darkModeToggle" className="toggle-label">
            <svg className="icon sun-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
            </svg>
            <svg className="icon moon-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 3a9 9 0 1 0 9 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 0 1-4.4 2.26 5.403 5.403 0 0 1-3.14-9.8c-.44-.06-.9-.1-1.36-.1z" />
            </svg>
          </label>
        </div>
      </nav>
    </header>
  );
};

export default Header;