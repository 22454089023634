import React from 'react';
import '../assets/css/referenzen.css';
import '../assets/css/global.css';


const Referenzen = () => {
  return (
    <main>
      <div className="flex-container">
        <h1 className="caption">Referenzen</h1>
        <p className="content">
          ALEXA VON HARDER PATISSERIE <br />
          ANTON DOLL HOLZMANUFAKTUR <br />
          BKS & PARTNER ARCHITEKTEN <br />
          BRANDHOCHZWEI <br />
          DÜRAUER HERRMANN BRÄNDLE TRAGWERKSPLANER <br />
          FELIX+JONAS ARCHITEKTEN <br />
          HOLZ ELF® TISCHMANUFAKTUR <br />
          KESSLER UND RUPP BERATENDER INGENIEUR UND ARCHITEKTIN <br />
          KÜCHE UND KONZEPT <br />
          LIWOOD <br />
          PFEIFEN HUBER <br />
          RESTAURANT FRITZI MASSARY <br />
          RISTORANTE CA’D’ORO <br />
          SCHREINEREI FRIESE <br />
          SPARK LE MONDE STORE <br />
          SUITED CONCEPT <br />
          SYNN ARCHITEKTEN <br />
          VIERZUEINS DESIGN <br />
        </p>
      </div>

      <div className="flex-container">
        <h2 className="caption">Kunden sagen</h2>
        <div className="content feedback">
          <blockquote>
            <p className="italic">
              Der Peter hat unsere Gesamte Kollektion Fotografiert. Er hat immer
              das beste aus den Produkten und Bildern rausgeholt und sein
              Qualitätsanspruch ist zudem sehr hoch. Unsere Bilder hat er uns auch
              zeitnah geliefert. Zusätzlich macht es auch Spaß mit Ihm zu
              arbeiten!
            </p>
            <p className="client">Jürgen Klanert, CEO Anton Doll Holzmanufaktur</p>
          </blockquote>
          
          <blockquote>
            <p className="italic">
              Herr Szekacs hat für unser Unternehmen einen Tisch in einer
              Kunstgalerie fotografiert und wir waren mehr als zufrieden mit dem
              Ergebnis. Für ein nächstes Projekt werden wir ihn auf jeden Fall
              wieder engagieren. Herr Szekacs ist ein sehr kompetenter und
              freundlicher Fotograf und wir können ihn nur weiterempfehlen.
            </p>
            <p className="client">Christa Braun, CEO holz elf® Tischmanufaktur</p>
          </blockquote>

          <blockquote>
            <p className="italic">
              Peter hat für mich alle Photos für den Webauftritt sowie für die
              Flyer-Werbung gemacht. Mit ihm zu arbeiten war eine tolle Erfahrung.
              Mit seinen Photographien ist es ihm tatsächlich gelungen die echte
              Atmosphäre bei uns im Restaurant einzufangen und bei den Food-Photos
              läuft einem das Wasser im Mund zusammen.
            </p>
            <p className="client">Jan Schmidt, Restaurant Fritzi Massary, München</p>
          </blockquote>
        </div>
      </div>

      <div className="flex-container">
        <h2 className="caption">In print</h2>
        <div className="content">
          <figure>
            <img src="#" alt="" />
            <figcaption>
              <p>
                Mikado 6/2023 - Unternehmer Magazin für Holzbau und Ausbau (S.
                22-25)
              </p>
            </figcaption>
          </figure>
          <br />
          <figure>
            <img src="assets/images/Referenzen/8272.jpg" alt="referenz" />
            <figcaption>
              <p>Architektouren 2021 - Bayerische Architektenkammer (S. 89)</p>
            </figcaption>
          </figure>
          <br />
          <figure>
            <img src="assets/images/Referenzen/002.png" alt="referenz" />
            <figcaption>
              <p>
                Darc Magazine 2019/20 - Decorative Lightning Design (S. 46-47)
              </p>
            </figcaption>
          </figure>
        </div>
      </div>
    </main>
  );
};

export default Referenzen;
