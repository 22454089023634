import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/global.css';  
import '../assets/css/footer.css';   

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setYear(new Date().getFullYear());
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const handleNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <p>
        Copyright © 2018-{year} Peter Szekacs Photography. All images and every content on this site are copyright protected.
        Do not use them without written permission!
      </p>
      <a href="/impressum" onClick={(e) => handleNavigation(e, '/impressum')}>Impressum</a> 
      <a href="/datenschutz" onClick={(e) => handleNavigation(e, '/datenschutz')}>Datenschutz</a>
    </footer>
  );
};

export default Footer;