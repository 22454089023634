import React from 'react';
import '../assets/css/global.css';

const Kontakt = () => {
  return (
    <main>
      <div className="flex-container">
        <h1 className="caption">Kontakt</h1>
        <p className="content">
          Peter Szekacs Photography<br />
          Email: <a href="mailto:info@peterszekacs.de">info@peterszekacs.de</a><br />
          Telefon: <a href="tel:+4917637626790">0176 37626790</a>
        </p>
      </div>
    </main>
  );
};

export default Kontakt;
