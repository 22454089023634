import React from "react";
import { Link, useNavigate } from "react-router-dom";
import '../assets/css/global.css';  
import '../assets/css/portfolio.css';  

const Portfolio = () => {
  return (
    <div className="portfolio-wrapper">
      <div className="portfolio-container">
        <div className="flex-container-portfolio">
          <figure className="portfolio-images">
            <Link to="/architektur">
              <img src="/assets/images/Montessori_Schule/4873.jpg" alt="architekturfotografie" className="architektur-image" />
            </Link>
            <figcaption>Architektur</figcaption>
          </figure>

          <figure className="portfolio-images">
            <Link to="/interieurfotografie">
              <img
                src="/assets/images/Wehrlestrasse/1165.jpg"
                alt="interieurfotografie"
                className="interieur-image portfolio-vertical-photo"
              />
            </Link>
            <figcaption>Interieur</figcaption>
          </figure>

          <figure className="portfolio-images">
            <Link to="/produktfotografie"> 
              <img src="/assets/images/Produkt/_MG_4254-2-Edit-2.jpg" alt="produktfotografie" className="produkt-image" />
            </Link>
            <figcaption>Produkt</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;