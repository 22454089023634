// App.js
// Hauptkomponente der Anwendung
// Importiert React-Hooks und React-Router für die Navigation

import React, { createContext, useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Importieren der Seiten
import Index from "./components/Index";
import Portfolio from "./components/Portfolio";
import Architektur from "./components/Architektur";
import Leistungen from "./components/Leistungen";
import Referenzen from "./components/Referenzen";
import Ueber from "./components/Ueber";
import Kontakt from "./components/Kontakt";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import Interieurfotografie from "./components/Interieurfotografie";
import Produktfotografie from "./components/Produktfotografie";

// Importieren der Komponenten
import Header from "./components/Header";
import Footer from "./components/Footer";

// Importieren der Stile
import "./assets/css/global.css";
import "./assets/css/dark-mode.css";

// Erstellen eines Kontexts für den Dark Mode
const DarkModeContext = createContext();

// Hook für die Verwendung des Dark Mode
export const useDarkMode = () => useContext(DarkModeContext);

function App() {
  // Zustand für den Dark Mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode !== null) {
      return savedDarkMode === "true";
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  });

  // Effekt für die Aktualisierung des Dark Mode
  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDarkMode);
    localStorage.setItem("darkMode", isDarkMode);

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setIsDarkMode(e.matches);

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, [isDarkMode]);

  // Funktion zum Umschalten des Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Render der Hauptanwendungsstruktur
  // Umfasst den DarkMode-Provider, Router, Header, Routen und Footer
  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/architektur" element={<Architektur />} />
          <Route path="/leistungen" element={<Leistungen />} />
          <Route path="/referenzen" element={<Referenzen />} />
          <Route path="/ueber" element={<Ueber />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/interieurfotografie" element={<Interieurfotografie />} />
          <Route path="/produktfotografie" element={<Produktfotografie />} />
        </Routes>
        <Footer />
      </Router>
    </DarkModeContext.Provider>
  );
}

export default App;