import React from 'react';
import '../assets/css/global.css';
import PortfolioNavigation from '../components/PortfolioNavigation';

const Produktfotografie = () => {
  return (
    <main>
      <div className="flex-container">
        <h1>Produktfotografie</h1>
      </div>
      <div className="project">
        <p>Hersteller: Anton Doll Holzmanufaktur</p>
        <img src="assets/images/Produkt/_MG_4254-2.jpg" alt="produkt" />
        <img src="assets/images/Produkt/_MG_4343-2.jpg" alt="produkt" />
        <img src="assets/images/Produkt/_MG_4304-Edit-2.jpg" alt="produkt" className="vertical-photo" />
        <img src="assets/images/Produkt/_MG_7402-Edit.jpg" alt="produkt" />
        <img src="assets/images/Produkt/_MG_7407-Edit.jpg" alt="produkt" />
        <img src="assets/images/Produkt/_MG_7476-Edit.jpg" alt="produkt" className="vertical-photo" />
      </div>
      <PortfolioNavigation currentPage="/produktfotografie" />
    </main>
  );
};

export default Produktfotografie;