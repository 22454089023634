// Index.js
// Hauptkomponente für die Startseite mit Bildslider

import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import '../assets/css/global.css';
import '../assets/css/slider.css';
import sliderImages from '../assets/json/slider_images.json';

const Index = () => {
  // State-Variablen für Bilder, Animation und Bildunterschriften
  const [images, setImages] = useState([]);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [captionVisible, setCaptionVisible] = useState(false);
  const infoButtonRef = useRef(null);

  // Effekt zum Laden der Bilder und Animieren des Info-Buttons
  useEffect(() => {
    setImages(sliderImages);
    setTimeout(() => {
      setAnimationComplete(true);
      if (infoButtonRef.current) {
        infoButtonRef.current.classList.add('animate');
        setTimeout(() => {
          if (infoButtonRef.current) {
            infoButtonRef.current.classList.remove('animate');
          }
        }, 1000);
      }
    }, 500);
  }, []);

  // Einstellungen für den Slider
  const settings = {
    dots: false,
    lazyLoad: 'ondemand',
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    pauseOnHover: false,
    fade: false,
    cssEase: 'cubic-bezier(.55,.085,.68,.53)',
  };

  // Funktion zum Umschalten der Bildunterschriften
  const toggleCaption = () => {
    setCaptionVisible(!captionVisible);
  };

  // Render des Sliders und des Info-Buttons
  return (
    <>
      <div className="slider-wrapper">
        <div className="slider-container">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="slider-item">
                <img src={image.src} alt={image.alt} className="slider-image" />
                <div className={`slider-caption ${captionVisible ? 'visible' : ''}`}>
                  {image.alt}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {animationComplete && (
          <div
            ref={infoButtonRef}
            className="info-button"
            onClick={toggleCaption}
          >
            i
          </div>
        )}
      </div>
    </>
  );
};

export default Index;