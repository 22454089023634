import React from 'react';
import '../assets/css/global.css';
import PortfolioNavigation from '../components/PortfolioNavigation';

const Interieurfotografie = () => {
  return (
    <main>
      <div className="flex-container">
        <h1>Interieurfotografie</h1>
      </div>
      <div className="project">
        <p>
          Tisch-Design: SUITED CONCEPT <br />
          Ort: Private Wohnung, München
        </p>
        <img src="assets/images/Linprunstr/8081.jpg" alt="interieur" />
        <img src="assets/images/Linprunstr/8051.jpg" alt="interieur" className="vertical-photo" />
        <img src="assets/images/Linprunstr/8004.jpg" alt="interieur" />
        <img src="assets/images/Linprunstr/7851.jpg" alt="interieur" />
        <img src="assets/images/Linprunstr/7965.jpg" alt="interieur" className="vertical-photo" />
      </div>
      <div className="project">
        <p>
          Planung: brandhochzwei <br />
          Ort: Dachgeschosswohnung, München
        </p>
        <img src="assets/images/Wehrlestrasse/1147.jpg" alt="interieur" />
        <img src="assets/images/Wehrlestrasse/1969.jpg" alt="interieur" className="vertical-photo" />
        <img src="assets/images/Wehrlestrasse/1171.jpg" alt="interieur" />
        <img src="assets/images/Wehrlestrasse/1957.jpg" alt="interieur" className="vertical-photo" />
        <img src="assets/images/Wehrlestrasse/1974.jpg" alt="interieur" />
        <img src="assets/images/Wehrlestrasse/1996.jpg" alt="interieur" className="vertical-photo" />
      </div>
      <PortfolioNavigation currentPage="/interieurfotografie" />
    </main>
  );
};

export default Interieurfotografie;