import React, { useState } from 'react';
import '../assets/css/global.css';
import '../assets/css/accordion.css';

const Leistungen = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); 
    } else {
      setActiveIndex(index); 
    }
  };

  return (
    <main>
      <div className="flex-container">
        <h1 className="caption">Leistungen</h1>
        <p className="content">
          Architekturfotografie <br />
          Interieurfotografie
        </p>
      </div>
      <div className="flex-container">
        <h2 className="caption">Arbeitsweise</h2>
        <div className="content">
          <ul className="accordion">
            {accordionData.map((item, index) => (
              <li key={index} className={`accordion-item ${activeIndex === index ? 'is-active' : ''}`}>
                <p
                  className="accordion-thumb"
                  onClick={() => toggleAccordion(index)}
                >
                  {item.title}
                </p>
                <div
                  className="accordion-panel"
                  style={{
                    maxHeight: activeIndex === index ? '1000px' : '0',
                    opacity: activeIndex === index ? '1' : '0',
                  }}
                >
                  <p>{item.content}</p>
                </div>
              </li>
            ))}
          </ul>
          <br />
          <p className="italic">
            Hinweis: Die Angaben auf dieser Seite gelten nicht als Angebot und
            dienen lediglich der Information. Ein konkretes Angebot erhalten Sie
            auf Anfrage per E-Mail.
          </p>
        </div>
      </div>
    </main>
  );
};

const accordionData = [
  {
    title: "Einsatzort",
    contentHeight: 80, 
    content:
      "München — Wien — Budapest + 150 km Umkreis. Weitere Entfernungen nach Absprache möglich.",
  },
  {
    title: "Architekturfotografie",
    contentHeight: 120,
    content:
      "Das Fotoshooting dauert ca. 3-4 Stunden bei kleineren Projekten. Größere Projekte brauchen mehr Zeit je nach Aufwand und der gewünschten Menge der Aufnahmen.",
  },
  {
    title: "Interieurfotografie",
    contentHeight: 150,
    content:
      "Das Fotoshooting dauert ca. 3-4 Stunden bei kleineren Projekten. Größere Projekte brauchen mehr Zeit je nach Aufwand und der gewünschten Menge der Aufnahmen. Bei einem Interieurfotoshooting haben Sie die Möglichkeit während des gesamten Shootings vor Ort die Aufnahmen in Echtzeit an einem Bildschirm zu folgen. Bei Bedarf werden zur Verbesserung der Lichtsituation Studioblitze verwendet.",
  },
  {
    title: "Bildauswahl",
    contentHeight: 90,
    content:
      "Nach dem Fotoshooting werden Vorschau-Fotos zur Verfügung gestellt, um die gewünschten Aufnahmen bestellen zu können. Sie können gegebenenfalls bereits vor Ort Aufnahmen auswählen.",
  },
  {
    title: "Bildbearbeitungsprozess",
    contentHeight: 90,
    content:
      "Alle übergebenen Aufnahmen werden sorgfältig bearbeitet und gegebenenfalls retuschiert. Dazu gehört insbesondere die originalgetreue Farbkorrektur.",
  },
  {
    title: "Datenübergabe",
    contentHeight: 70,
    content: "Die Aufnahmen erhalten Sie per Weblink zum Herunterladen.",
  },
  {
    title: "Bildnutzungsrechte",
    contentHeight: 150,
    content:
      "Zur rechtmäßigen Nutzung der Aufnahmen werden Ihnen mit dem Kauf der Fotos bestimmte Bildnutzungsrechte erteilt. Dabei stehen je nach gewünschtem Verwendungszweck verschiedene Kategorien an Bildnutzungsrechten zur Auswahl.",
  },
  {
    title: "Preise und Angebote",
    contentHeight: 250,
    content:
      "Bei Interesse schicken Sie bitte Ihre Anfrage an meine E-Mail-Adresse und ich unterbreite Ihnen so schnell wie möglich ein unverbindliches und persönliches Angebot. Bei Anfragen bitte besonders folgende Informationen angeben: Voraussichtlicher Termin und Ort des Fotoshootings, Art und Größe des Projekts, Gewünschte Menge der Aufnahmen, Verwendungszweck der Aufnahmen. Falls vorhanden, gerne ein Paar Handy Fotos über Projekt und ein grober Grundriss.",
  },
];

export default Leistungen;
