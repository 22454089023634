import React from 'react';
import '../assets/css/global.css';

const Datenschutz = () => {
  return (
    <main>
      <div className="flex-container">
        <h1 className="caption">Datenschutzerklärung</h1>
        <div className="content">
          <h3>1. Verantwortliche Stelle</h3>
          <br />
          <p>
            Peter Szekacs <br />
            Cosimastr. 204 <br />
            81927 München <br />
            Telefon: <a href="tel:+4917637626790">017637626790</a> <br />
            E-Mail: info (at) peterszekacs (dot) de
          </p>
          <br />

          <h3>2. Datenerfassungen und deren Zwecke</h3>
          <br />
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
          </p>
          <br />
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
          </p>
          <br />

          <h4>Besuch der Website</h4>
          <br />
          <p>
            Beim Besuch dieser Website werden automatisch personenbezogene Daten durch unseren externen Hoster (siehe Punkt 3) erfasst (siehe unten) zum Zweck der Vertragserfüllung- und Anbahnung mit unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <br />

          <h4>Anfrage per E-Mail oder Telefon</h4>
          <br />
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
          </p>
          <br />

          <h4>Speicherdauer</h4>
          <br />
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <br />

          <h3>3. Betroffenenrechte</h3>
          <br />

          <h4>Auskunft, Löschung und Berichtigung</h4>
          <br />
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>
          <br />

          <h4>Recht auf Datenübertragbarkeit</h4>
          <br />
          <p>
            Sie haben das Recht, personenbezogene Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </p>
          <br />

          <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
          <br />
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <br />

          <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
          <br />
          <p>
            Im Falle von mutmaßlichen Verstößen gegen die DSGVO können Sie als Betroffene Person Beschwerde bei der folgenden Datenschutz-Aufsichtsbehörde einreichen:
          </p>
          <br />

          <p>Bayerisches Landesamt für Datenschutzaufsicht</p>
          <p>
            Postfach 1349 <br />
            91504 Ansbach <br />
            Telefon: +49 (0) 981 180093-0 <br />
            Telefax: +49 (0) 981 180093-800 <br />
            <a href="https://www.lda.bayern.de/de/beschwerde.html" target="_blank" rel="noopener noreferrer">https://www.lda.bayern.de/de/beschwerde.html</a>
          </p>
          <br />

          <h3>4. Hosting</h3>
          <br />
          <p>
            Diese Website wird bei Squarespace gehostet (externer Dienstleister).
          </p>
          <p>Weitere Informationen finden Sie in der Datenschutzerklärung des Hosters: <a href="https://www.squarespace.com/privacy" target="_blank" rel="noopener noreferrer">https://www.squarespace.com/privacy</a></p>
          <br />

          <h4>SSL- bzw. TLS-Verschlüsselung</h4>
          <br />
          <p>
            Diese Seite nutzt aus Sicherheitsgründen eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <br />

          <h4>Cookies</h4>
          <br />
          <p>
            Der Hoster Squarespace verwendet so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder längerfristig (für die Dauer von 2 Jahren) auf Ihrem Endgerät gespeichert.
          </p>
          <p>Weitere Informationen finden Sie in der Datenschutzerklärung des Hosters unter „Cookies“: <a href="https://www.squarespace.com/privacy" target="_blank" rel="noopener noreferrer">https://www.squarespace.com/privacy</a></p>
          <br />

          <p>Quelle: <a href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer">https://www.e-recht24.de</a></p>
        </div>
      </div>
    </main>
  );
};

export default Datenschutz;
