import React from 'react';
import '../assets/css/global.css';

const Ueber = () => {
  return (
    <main>
      <div className="flex-container">
        <h2 className="caption">Mein Fokus</h2>
        <div className="content">
          <h3>Mein Fokus liegt auf Unternehmen der Baubranche und auf Firmen, die Design schaffen.</h3>
          <br />
          <p>
            Mit großer Leidenschaft unterstütze ich Ihre Firma bei jeder fotografischen Anforderung und gerne helfe ich mit, um Ihre Ziele im Bereich visuelle Kommunikation zu erreichen. <br />
            Lassen Sie uns gemeinsam Ihr Projekt optimal in Szene setzen. Ich freue mich, von Ihnen und Ihrem Projekt zu hören.
          </p>
        </div>
      </div>

      
      <div className="flex-container">
        <h1 className="caption">Über mich</h1>
        <div className="content">
          <p>
            Ich bin seit 2018 ein eingetragener Fotograf in der Handwerkskammer für München und Oberbayern. Ich lebe mit meiner Frau und meinen zwei Söhnen in München. Jahrgang: 1989
          </p>
          <br />
          <img src="assets/images/portrait.jpg" alt="portrait" />
        </div>
      </div>
    </main>
  );
};

export default Ueber;
