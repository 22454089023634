import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/portfolioNavigation.css';

const portfolioPages = [
  { path: '/architektur', name: 'Architektur' },
  { path: '/interieurfotografie', name: 'Interieur' },
  { path: '/produktfotografie', name: 'Produkt' }
];

const PortfolioNavigation = ({ currentPage }) => {
  const currentIndex = portfolioPages.findIndex(page => page.path === currentPage);
  const prevPage = portfolioPages[currentIndex - 1];
  const nextPage = portfolioPages[currentIndex + 1];

  return (
    <div className="portfolio-navigation">
      {prevPage && (
        <Link to={prevPage.path} className="nav-link prev">
          <span className="arrow"></span>
          <span className="text">{prevPage.name}</span>
        </Link>
      )}
      {nextPage && (
        <Link to={nextPage.path} className="nav-link next">
          <span className="text">{nextPage.name}</span>
          <span className="arrow"></span>
        </Link>
      )}
    </div>
  );
};

export default PortfolioNavigation;